import React from "react";

import ProductCart from "./productCart";
import OrderSummary from "./orderSummary";

function FormCart({
  products = [],
  removeItem,
  updateItem,
  resetCart,
  subtotalPrice,
  webUrl,
}) {
  return (
    <form className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
      <section aria-labelledby="cart-heading" className="lg:col-span-7">
        <ul
          role="list"
          className="border-t border-b border-gray-200 divide-y divide-gray-200"
        >
          {products.length > 0 &&
            products.map(product => {
              const { title, variant, quantity, id } = product;

              const productLink = `/products/${variant.product.handle}`;
              const singlePrice = `$ ${variant.price.amount}`;

              // const rawProductName = `${title} <br/> <p class="mt-0 mb-1">Type: ${variant.selectedOptions[0]?.name} - ${variant.selectedOptions[0]?.value}</p>`;

              const rawType = variant?.sku || "Default Type";
              const rawProductName = `${title} <br/> <p class="mt-0 mb-1">Type: ${rawType}</p>`;
              const imgSrc = variant.image.src;
              const isAvailable = variant.available;
              const vanriantId = variant.id;
              const lineItemId = id;

              return (
                <ProductCart
                  imgSrc={imgSrc}
                  productLink={productLink}
                  singlePrice={singlePrice}
                  productName={rawProductName}
                  quantity={quantity}
                  isAvailable={isAvailable}
                  key={vanriantId}
                  removeItem={removeItem}
                  updateItem={updateItem}
                  lineItemId={lineItemId}
                />
              );
            })}
        </ul>
      </section>

      {/* Order summary */}
      <OrderSummary
        resetCart={resetCart}
        subtotalPrice={subtotalPrice}
        webUrl={webUrl}
      />
    </form>
  );
}

export default FormCart;
