import React, { useState } from "react";
import {
  CheckIcon,
  ClockIcon,
  XIcon as XIconSolid,
} from "@heroicons/react/solid";
import { Link } from "gatsby";

function ProductCart({
  imgSrc,
  productLink,
  singlePrice,
  productName,
  isAvailable,
  quantity,
  removeItem,
  updateItem,
  lineItemId,
  isRelatedRow,
}) {
  const [updatedQuantity, setUpdateQuantity] = useState(quantity);

  const _handleChangedQuantity = e => {
    const { value } = e.target;
    setUpdateQuantity(value);
    updateItem({ id: lineItemId, quantity: value });
  };
  const _handleRemoveProduct = e => {
    e.preventDefault();
    // add remove fn here vanriantId
    removeItem(lineItemId);
  };
  return (
    <li className="flex py-6 sm:py-10">
      <div className="flex-shrink-0">
        <img
          src={imgSrc}
          alt={productName}
          className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
        />
      </div>

      <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
        <div
          className={`relative pr-9 sm:grid sm:gap-x-6 sm:pr-0 ${
            !isRelatedRow ? "sm:grid-cols-2" : ""
          }`}
        >
          <div>
            <div className="flex justify-between">
              <h3 className="leading-3">
                <Link
                  to={productLink}
                  className="font-medium hover:text-buttonHover text-primary"
                  dangerouslySetInnerHTML={{ __html: productName }}
                />
              </h3>
            </div>

            <p className="mt-1 text-sm font-medium text-gray-900">
              {singlePrice}
            </p>
          </div>

          {!isRelatedRow && (
            <div className="mt-4 sm:mt-0 sm:pr-9">
              <label
                htmlFor={`quantity-${quantity}`}
                className="sr-only"
                dangerouslySetInnerHTML={{ __html: productName }}
              />
              <select
                id={`quantity-${quantity}`}
                name={`quantity-${quantity}`}
                className="max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={updatedQuantity}
                onChange={e => _handleChangedQuantity(e)}
              >
                {Array.from(Array(10).keys()).map(i => (
                  <option value={i + 1} key={i}>
                    {i + 1}
                  </option>
                ))}
              </select>

              <div className="absolute top-0 right-0">
                <button
                  type="button"
                  className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                  onClick={e => _handleRemoveProduct(e)}
                >
                  <span className="sr-only">Remove</span>
                  <XIconSolid className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          )}
        </div>

        <p className="mt-4 flex text-sm text-gray-700 space-x-2">
          {isAvailable ? (
            <CheckIcon
              className="flex-shrink-0  w-12 h-12 text-green-500"
              aria-hidden="true"
            />
          ) : (
            <ClockIcon
              className="flex-shrink-0  w-12 h-12 text-gray-300"
              aria-hidden="true"
            />
          )}

          <span>{isAvailable && "In stock"}</span>
        </p>
      </div>
    </li>
  );
}

export default ProductCart;
