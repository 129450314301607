import React from "react";
import { Container, CircularProgress } from "@material-ui/core";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import Layout from "../components/theme/layout";
import Seo from "../components/theme/seo";
import ShoppingLayout from "../components/shopping/shoppingLayout";

import { useShopifyFunctions } from "../context/StoreContext";

import FormCart from "../components/formCart";
import ProductCart from "../components/formCart/productCart";

export default function Cart({ data }) {
  const layoutData = [...data.allStrapiCoversAccessories.edges];
  const { checkout, removeItem, resetCart, updateItem } = useShopifyFunctions();
  const { loaded, lineItems, subtotalPrice, webUrl } = checkout;

  const allProductCollections = data.allShopifyCollection.edges.map(
    ({ node }) => node
  );

  // get all products from all categories and remove products with no images
  const allProducts = allProductCollections.reduce((acc, curr) => {
    const products = curr.products.filter(
      item => item.images.length > 0 && item.status === "ACTIVE"
    );
    return acc.concat(products);
  }, []);

  const relatedProducts = allProducts
    .filter(p => {
      const mapB = lineItems.map(item => item.variant.product.id);
      return !mapB.includes(p.shopifyId);
    })
    .sort(() => 0.5 - Math.random())
    .slice(0, 4);
  return (
    <Layout>
      <Seo title="Shopping Cart" />
      {loaded ? (
        <ShoppingLayout data={layoutData} shopName="Shopping Cart.">
          <div className="bg-white my-24">
            <Container>
              <h2 className="">Shopping Cart</h2>

              {lineItems.length ? (
                <FormCart
                  products={lineItems}
                  subtotalPrice={subtotalPrice?.amount}
                  webUrl={webUrl}
                  removeItem={removeItem}
                  resetCart={resetCart}
                  updateItem={updateItem}
                />
              ) : (
                <>
                  <h3>Your Shopping Cart is empty</h3>
                  <p>
                    Your shopping cart lives to serve. Give it purpose – fill it
                    with{" "}
                    <Link
                      to="/shop"
                      title="Cart"
                      className="teal font-semibold hover:opacity-50 transition duration-300 ease-in-out"
                    >
                      products
                    </Link>
                    .
                  </p>
                </>
              )}

              <section aria-labelledby="related-heading" className="mt-24">
                <h2
                  id="related-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  You may also like&hellip;
                </h2>

                <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                  {relatedProducts.length > 0 &&
                    relatedProducts.map(relatedProduct => {
                      const {
                        title,
                        variants,
                        quantity,
                        id,
                        handle,
                        productType,
                        images,
                      } = relatedProduct;

                      const productLink = `/products/${handle}`;
                      const singlePrice = `$ ${variants[0]?.price}`;
                      const rawProductName = `${title} <br/> <p class="mt-0 mb-1">Type: ${productType} - ${variants[0]?.price}</p>`;
                      const imgSrc = images[0]?.originalSrc;
                      const vanriantId = id;
                      const lineItemId = id;
                      return (
                        <ProductCart
                          imgSrc={imgSrc}
                          productLink={productLink}
                          singlePrice={singlePrice}
                          productName={rawProductName}
                          quantity={quantity}
                          isAvailable={true}
                          vanriantId={vanriantId}
                          key={vanriantId}
                          removeItem={removeItem}
                          updateItem={updateItem}
                          lineItemId={lineItemId}
                          isRelatedRow
                        />
                      );
                    })}
                </div>
              </section>
            </Container>
          </div>
        </ShoppingLayout>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
}

export const query = graphql`
  query Cart {
    allStrapiCoversAccessories {
      edges {
        node {
          id # id of the node
          ca_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          access_outro {
            id
            piggy_outro
          }
        }
      }
    }
    allShopifyCollection {
      edges {
        node {
          id
          title
          shopifyId
          productsCount
          products {
            id
            status
            storefrontId
            title
            productType
            handle
            images {
              originalSrc
            }
            variants {
              image {
                originalSrc
                id
              }
              price
              displayName
              id
              sku
              weight
            }
          }
        }
      }
    }
  }
`;
