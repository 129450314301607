import React from "react";

function OrderSummary({
  // resetCart,
  subtotalPrice,
  webUrl,
}) {
  return (
    <section
      aria-labelledby="summary-heading"
      className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
    >
      <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
        Order summary
      </h2>

      <dl className="mt-6 space-y-4">
        <div className="flex items-center justify-between">
          <dt className="text-sm text-gray-600">Subtotal</dt>
          <dd className="text-sm font-medium text-gray-900">
            ${subtotalPrice}
          </dd>
        </div>
        <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
          <dt className="flex items-center text-sm text-gray-600">
            <span>Shipping estimate</span>
          </dt>
          <dd className="text-sm font-medium text-gray-900">
            Calculated at next step{" "}
          </dd>
        </div>
      </dl>

      <div className="mt-6">
        <a
          type="submit"
          className="w-full bg-primary border border-transparent rounded-md py-6 flex items-center justify-center  text-white hover:bg-buttonHover focus:outline-none  focus:bg-buttonHover text-base"
          href={webUrl}
          // rel="noopener noreferrer"
          // target="_blank"
        >
          Checkout
        </a>
      </div>
    </section>
  );
}

export default OrderSummary;
